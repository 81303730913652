import React from "react";
import FooterItem from "./footerItem";
import pdf from "../../assets/Doc/Public Portal Documentation.pdf";


class Footer extends React.Component {

  render() {
    return (
      <div className="footer" id="footer">
        <div className="overlay"></div>
        <div className="container">
          <FooterItem
            title="Kenya Space Agency"
            info="Mandate: Promote, coordinate and regulate space related activities in kenya"
          />
          <FooterItem
            title="Sitemap"
            i1="Home"
            link1="/"
            i2="Browse Data"
            link2="/categories/All"
            i3="Data Visualization"
            link3="https://maps.ksa.go.ke"
          />
          {/* ! TODO: Add the following links to the footer when content is resolved */}
          {/* <FooterItem
            title="LEGAL"
            i1="Terms and Conditions"
            link1="/terms"
            i2="Privacy Policy"
            link2="/privacypolicy"
          />
          <FooterItem
            title="Quick Links"
            i1="FAQs"
            link1="/faqs"
            ff="Give feedback"
          />    */}
          <FooterItem
            title="CONTACTS"
            mail="gisimagery@ksa.go.ke"
            num1="+254 709 298 200"
            social={true}
          />
        </div>
        <span className="copyright">Copyright 2024. All rights reserved.</span>
      </div>
    );
  }
}

export default Footer;
