import { React } from "react";


function NotFound() {
	return (
		<div>
			<h3
				data-aos={"fade-up"}
				data-aos-offset={300}
				data-aos-duration={900}
				data-aos-delay={600}
			>
				This map is being reconstructed. Please check back later. <br/> <a href="/categories">&larr; Go back Home</a>
			</h3>
		</div>
	);
}

export default NotFound;
