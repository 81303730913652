import { useEffect, useState } from "react";
import Pagination from "../Utils/pagination";
import "../../Styles/documents.scss";
import WaveLoading from "../Utils/WaveLoading";
import SelectMain from "../Utils/SelectMain";
import { useRef } from "react";
import placeholder from "../../assets/imgs/folder1.png";

const criteria = ["Title", "Description", "Owner", "Keywords"];

export default function MapCategory(props) {
  const [active, setActive] = useState("All");
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [crit, setCrit] = useState(criteria[0]);
  const [loading, setLoading] = useState(null);
  const [filter, setFilter] = useState("All");
  const [refresh, setRefresh] = useState(false);
  const srch = useRef();

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    // Get the current URL
    const currentUrl = window.location.href;

    const urlParts = currentUrl.split("/");

    // Get the last item from the URL
    const lastItem = urlParts[urlParts.length - 1];

    setActive(lastItem);
  }, []);

  useEffect(() => {
    if (active == "All") {
      setLoading(true);
      fetch(`/api/data/all/${(currentPage - 1) * 12}`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setLoading(false);
          setData(data);
        })
        .catch((e) => {
          setLoading(false);
        });
    } else {
      if (filter == "All") {
        setLoading(true);
        fetch(`/api/data/category/${active}/${(currentPage - 1) * 12}`)
          .then((res) => {
            if (res.ok) return res.json();
            else throw Error("");
          })
          .then((data) => {
            setLoading(false);

            setData(data);
          })
          .catch((e) => {
            setLoading(false);
          });
      } else {
        setLoading(true);
        fetch(
          `/api/data/tpaginated/${active}/${filter}/${(currentPage - 1) * 12}`
        )
          .then((res) => {
            if (res.ok) return res.json();
            else throw Error("");
          })
          .then((data) => {
            setLoading(false);

            setData(data);
          })
          .catch((e) => {
            setLoading(false);
          });
      }
    }
  }, [currentPage, refresh, filter, active]);

  function search(q) {
    setLoading(true);
    fetch(`/api/data/search/${crit}/${q}/${(currentPage - 1) * 12}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        setData(data);
      })
      .catch((e) => {
        setLoading(false);
      });
  }

  return (
    <div className="arc">
      <div className="slist">
        <div className="scontainer">
          <div className="left">
            <span>Category</span>
            <div className="itemsContainer">
              <a
                className={active == "All" ? "activated" : ""}
                href="/categories/All"
              >
                All
              </a>
	  <a
                className={
                  active == "Kenya%20Floods%20Watch" ? "activated" : ""
                }
                href="/categories/Kenya%20Floods%20Watch"
              >
                Floods
              </a>	
              <a
                className={
                  active == "Climate%20and%20Weather" ? "activated" : ""
                }
                href="/categories/Climate%20and%20Weather"
              >
                Climate and Weather
              </a>
              <a
                className={active == "Agriculture" ? "activated" : ""}
                href="/categories/Agriculture"
              >
                Agriculture
              </a>
              <a
                className={active == "Disaster" ? "activated" : ""}
                href="/categories/Disaster"
              >
                Disaster
              </a>
              <a
                className={active == "Natural%20Resources" ? "activated" : ""}
                href="/categories/Natural%20Resources"
              >
                Natural Resources
              </a>
              <a
                className={active == "Spatial%20Planning" ? "activated" : ""}
                href="/categories/Spatial%20Planning"
              >
                Spatial Planning
              </a>
              <a
                className={active == "General%20Data" ? "activated" : ""}
                href="/categories/General%20Data"
              >
                General Data
              </a>
              <a
                className={active == "Time%20Series" ? "activated" : ""}
                href="/categories/Time%20Series"
              >
                Time Series
              </a>
            </div>
            {active != "All" && (
              <>
                <span>Data Type</span>
                <div className="itemsContainer">
                  <Category
                    txt="All"
                    filter={filter}
                    setFilter={setFilter}
                    setCurrentPage={setCurrentPage}
                  />
                  <Category
                    txt="Raster"
                    filter={filter}
                    setFilter={setFilter}
                    setCurrentPage={setCurrentPage}
                  />
                  <Category
                    txt="Vector"
                    filter={filter}
                    setFilter={setFilter}
                    setCurrentPage={setCurrentPage}
                  />
                </div>
              </>
            )}
          </div>

          <div className="smain">
            <div className="top">
              <dir className="yr">
                <h4>Search Criteria</h4>
                <SelectMain
                  data={criteria}
                  getSelected={(v) => {
                    setCrit(v);
                  }}
                />
              </dir>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <input
                  ref={srch}
                  type="text"
                  placeholder={"Searching by " + crit}
                  required
                  onChange={(e) => {
                    if (e.target.value == "") {
                      setData(null);
                      setRefresh(!refresh);
                    } else {
                      search(e.target.value);
                    }
                  }}
                />
                <i className="fa fa-search"></i>
              </form>
            </div>

            <div className="list">
              {data &&
                data?.data?.length > 0 &&
                data?.data?.map((item, i) => {
                  return <MyDocument key={i} item={item} />;
                })}
            </div>
            <br />
            <br />
            {data && (
              <Pagination
                totalItems={data.total}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            )}
          </div>
        </div>
        {loading && <WaveLoading />}
      </div>
    </div>
  );
}

const Category = (props) => {
  return (
    <div
      onClick={() => {
        props.setFilter(props.txt);
        props.setCurrentPage(1);
      }}
      style={{
        backgroundColor: props.txt == props.filter ? "#60606030" : "white",
      }}
      className="category"
    >
      <p>{props.txt}</p>
    </div>
  );
};

const ProductBadge = (props) => {
  let text;
  if (props.name == "Time Series") {
    text = "Time Series";
  } else if (props.name != "Time Series" && !props.collection) {
    text = "Product";
  } else if (props.collection) {
    text = "Collection";
  } else return;

  return <span className="badge">{text}</span>;
};

const MyDocument = (props) => {
  return (
    <div
      title={
        props.item.Collection
          ? `Collection: ${props.item.Collection}`
          : `Map Description: \n\n ${props.item.Description}`
      }
      className={props.item.Collection ? "stk col" : "stk"}
      onClick={() => {
        if (props.item.Collection) {
          window.location.href = `/collections/${props.item.Collection}`;
        } else {
          window.location.href = `/maps/${props.item.Category}/${props.item.ID}`;
        }
      }}
    >
      <div className="thumbnailContainer">
        {props.item.Collection ? (
          <img
            className="silhouette"
            src={placeholder}
            alt="Image silhouette"
          />
        ) : (
          ""
        )}
        <img
          className="thumbnail"
          src={`/api/${props.item.Thumbnail}`}
          alt="dataset thumbnail"
        />
      </div>
      <div className="np">
        <h4>
          <b>{props.item.Title}</b>
        </h4>
        <ProductBadge
          name={props.item.Category}
          collection={props.item.Collection}
        />
        <h4>Owner: {props.item.Owner}</h4>
        {/* {props.item.Collection && <h4><b>Collection: {props.item.Collection}</b></h4>} */}
        <h4>
          {props.category}Date Published: {props.item.updatedAt.split("T")[0]}
        </h4>
        <p>{props.item.Description}</p>
      </div>
    </div>
  );
};
