import { useEffect, useState } from "react";
import hero from "../../assets/imgs/hero.jpg";
import "../Landing/Cards.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import Header from "../Utils/header";
import Cards from "../Landing/Cards";
import CoolText from "./CoolText";
import Floods from "../Utils/Floods";
SwiperCore.use([Autoplay, Pagination]);

export default function Background(params) {
  const [index, setIndex] = useState(0);
  const [cls, setCls] = useState("");
 
  const content = [
    {
      title: "Kenya Floods Watch",
      desc: "provides the best Software solutions that you require to boost your business.",
    },
    {
      title: "Agriculture",
      desc: "provides the best Software solutions that you require to boost your business.",
    },
    {
      title: "Climate and Weather",
      desc: "features swiftly made, easily edited, beautifully deployed apps",
    },
    {
      title: "Natural Resources",
      desc: "provides for a simple, magical way to connect with people",
    },
    {
      title: "Spatial Planning",
      desc: "make apps that feel good to use and bring joy to those you’re offering them to",
    },
    {
      title: "Disaster",
      desc: "make apps that feel good to use and bring joy to those you’re offering them to",
    },
    {
      title: "General Data",
      desc: "make apps that feel good to use and bring joy to those you’re offering them to",
    },
  ];
  useEffect(() => {
    setInterval(() => {
      setCls("fadeOut");
    }, 1000);
  }, []);

  return (
    <div className="bg">
      {/* <img className={cls} src={imgs[index]} alt="" /> */}
      <img className={hero} src='https://images.unsplash.com/photo-1579818549693-04c7afca6318?q=80&w=4160&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' alt="" />
      <div className="nhero">
        <Swiper
          autoplay={{
            delay: 5500,
            disableOnInteraction: false,
          }}
          loop={true}
          grabCursor={true}
          pagination={true}
          modules={[Pagination]}
          className="mySwiper"
        >
          {content.map((item, i) => {
            return (
              <SwiperSlide key={i}>
                <MySlider item={item} />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div className="themes">
          {content.map((item, index) => {
            return (
              <p onClick={() => {
                window.location.href = item.title == "Kenya Floods Watch" ? "/publications":"/categories/" + item.title;
              }}>{ item.title == "Kenya Floods Watch" ? "Reports" : item.title }</p>
            )
          })}
      </div>

        <div className="coffee-medium__smoke coffee-medium__smoke-one"></div>
        <div className="coffee-medium__smoke coffee-medium__smoke-two"></div>
        <div className="coffee-medium__smoke coffee-medium__smoke-three"></div>
        <div className="coffee-medium__smoke coffee-medium__smoke-for"></div>
        <div className="coffee-medium__cup"></div>
      </div>
    </div>
  );
}

const MySlider = (props) => {

  return (
    <div className="s_hero">
      <div data-aos="fade-up" className="left">
        <h1>{props.item.title == "Kenya Floods Watch" ? "Floods" : props.item.title}</h1>
        <div>
          <h4>
            Explore the Kenya Space Agency <br></br> Data Hub
          </h4>
        </div>

        <button
          onClick={() => {
            window.location.href = "/categories/" + props.item.title;
          }}
        >
          Explore Data
        </button>
      </div>
      <CoolText />
    </div>
  );
};
